import {
  isServiceOfferedAsPricingPlan,
  PaymentDtoMapper,
  PriceUtils,
} from '@wix/bookings-uou-mappers';
import {
  OfferedAsType,
  Service,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';

import { CalendarContext } from '../context/contextFactory';
import { TFunction } from '../../components/BookingCalendar/controller';
import { SelectedVariantOptions } from '../../types/types';

export const PRICE_DESCRIPTION_DELIMITER = ' | ';

export const getPaymentDescription = ({
  context,
  selectedVariantsOptions = [],
  service,
}: {
  context: CalendarContext;
  selectedVariantsOptions?: SelectedVariantOptions[];
  service: Service;
}) => {
  const paymentDescription = [];
  const {
    settings,
    settingsParams,
    isPricingPlanInstalled: isServiceBookableWithPricingPlan,
    businessInfo,
    t,
  } = context;
  const regionalSettingsLocale = businessInfo!.regionalSettingsLocale!;

  const payment = service.payment;
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );

  if (isOfferedAsOneTime(payment)) {
    let priceText = getServicePriceText(payment, regionalSettingsLocale, t);

    if (
      payment.paymentDetails.isVariedPricing &&
      selectedVariantsOptions.length > 0
    ) {
      const totalAmount = selectedVariantsOptions.reduce(
        (previousValue, { price, numberOfParticipants }) =>
          previousValue + Number(price.value!) * numberOfParticipants,
        0,
      );
      priceText = PriceUtils.getFormattedCurrency({
        price: totalAmount,
        currency: selectedVariantsOptions[0].price.currency!,
        locale: regionalSettingsLocale,
      });
    }

    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return (
    paymentDescription
      // remove empty items
      .filter((priceItem) => !!priceItem.trim())
      .join(PRICE_DESCRIPTION_DELIMITER)
  );
};

export const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

export const getServicePriceText = (
  payment: ServicePayment,
  regionalSettingsLocale: string,
  t?: TFunction,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(
    regionalSettingsLocale,
    (price) =>
      paymentDto.isVariedPricing && t
        ? t('payment.from-price', { price })
        : price,
  );
  return paymentDtoMapper.priceText(paymentDto);
};
