import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  createFilterViewModels,
  FilterViewModel,
  memoizedFiltersViewModel,
} from '../filterViewModel/filterViewModel';
import {
  CalendarContext,
  CalendarViewMode,
} from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { Optional, SourceOptions } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
  TimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import { isCalendarPage, isCalendarWidget } from '../../../../utils/presets';
import {
  createServiceSelectionViewModel,
  memoizedServiceSelectionViewModel,
  ServiceSelectionViewModel,
} from '../serviceSelectionViewModel/serviceSelectionViewModel';

export type HeaderViewModel = {
  title?: string;
  subtitle?: string;
  filters: FilterViewModel[];
  timezoneSelectionViewModel?: TimezoneSelectionViewModel;
  serviceSelectionViewModel?: ServiceSelectionViewModel;
};

export const memoizedHeaderViewModel: MemoizedViewModalFactory<
  Optional<HeaderViewModel>
> = {
  dependencies: {
    state: ['servicesInView'],
    settings: [
      'headerSubtitleVisibility',
      'headerSubtitleSource',
      'headerSubtitle',
      'headerFiltersVisibility',
      'headerTitle',
      'headerTitleVisibility',
      'headerTimezoneSelectionVisibility',
      'headerVisibility',
    ],
    subDependencies: [
      memoizedFiltersViewModel.dependencies,
      memoizedTimezoneSelectionViewModel.dependencies,
      memoizedServiceSelectionViewModel.dependencies,
    ],
  },
  createViewModel: createHeaderViewModel,
};

export function createHeaderViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<HeaderViewModel> {
  const { settings, settingsParams, getContent, preset, viewMode } = context;
  const { servicesInView } = state;

  const isHeaderVisible = settings.get(settingsParams.headerVisibility);
  if (!isHeaderVisible) {
    return undefined;
  }
  const isCalendar = isCalendarPage(preset) || isCalendarWidget(preset);
  const isFullViewMode = viewMode === CalendarViewMode.FULL;

  let title: string | undefined;
  const isTitleVisible =
    isFullViewMode && settings.get(settingsParams.headerTitleVisibility);

  if (isTitleVisible) {
    title = isCalendar
      ? servicesInView[0].info.name
      : getContent({
          settingsParam: settingsParams.headerTitle,
          translationKey: 'app.settings.defaults.header-title',
        });
  }

  let subtitle;
  const isSubtitleVisible =
    isFullViewMode && settings.get(settingsParams.headerSubtitleVisibility);

  if (isSubtitleVisible) {
    if (
      settings.get(settingsParams.headerSubtitleSource) ===
      SourceOptions.SERVICE
    ) {
      subtitle = servicesInView[0].info.tagline;
    } else {
      subtitle = getContent({
        settingsParam: settingsParams.headerSubtitle,
        translationKey: 'app.settings.defaults.header-subtitle',
      });
    }
  }

  const filters = isFullViewMode
    ? createFilterViewModels({ state, context })
    : [];

  const isTimezoneSelectionVisible = settings.get(
    settingsParams.headerTimezoneSelectionVisibility,
  );
  const timezoneSelectionViewModel = isTimezoneSelectionVisible
    ? createTimezoneSelectionViewModel({
        state,
        context,
      })
    : undefined;
  const serviceSelectionViewModel = createServiceSelectionViewModel({
    state,
    context,
  });

  const isAtLeastOneHeaderElementExists =
    !!title ||
    !!subtitle ||
    filters.length ||
    !!timezoneSelectionViewModel ||
    !!serviceSelectionViewModel;
  if (isAtLeastOneHeaderElementExists) {
    return {
      title,
      subtitle,
      filters: filters!,
      timezoneSelectionViewModel,
      serviceSelectionViewModel,
    };
  }
}
