import { CalendarBiLogger } from '../biLoggerFactory';
import { LayoutOptions, TriggeredByOptions } from '../../../types/types';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  getTimeSlotAvailabilityStatus,
  getTimeSlotsAvailabilityStatuses,
  TimeSlotAvailabilityStatus,
} from '../../timeSlots/timeSlots';
import { mapTimeSlotsByAvailabilityStatusesToTimeSlotsAvailability } from '../mappers';
import {
  bookingsCalendarDatePickerLoad,
  bookingsCalendarTimePickerLoad,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { timeSlotLayouts } from '../../layouts';
import { TimeSlotsAvailability } from '../consts';
import { DayName, getDayName } from '../../dateAndTime/dateAndTime';

export const sendDatePickerLoadedBiEvent = ({
  biLogger,
  availableSlotsPerDay,
  triggeredBy,
}: {
  biLogger: CalendarBiLogger;
  availableSlotsPerDay?: QueryAvailabilityResponse;
  triggeredBy: TriggeredByOptions;
}) => {
  let dateAvailability;
  if (availableSlotsPerDay) {
    dateAvailability = JSON.stringify({
      datesWithAvailableSlots:
        availableSlotsPerDay.availabilityEntries?.filter(
          (availabilityEntry: SlotAvailability) => availabilityEntry.bookable,
        ).length || 0,
      datesWithUnavailableSlots:
        availableSlotsPerDay.availabilityEntries?.filter(
          (availabilityEntry: SlotAvailability) => !availabilityEntry.bookable,
        ).length || 0,
    });
  }
  biLogger.report(
    bookingsCalendarDatePickerLoad({
      dateAvailability,
      triggeredBy,
    }),
  );
};

export const sendTimePickerLoadedBiEvent = ({
  availableSlots,
  selectedDate,
  triggeredBy,
  biLogger,
  calendarLayout,
  isReportSlotsPerDayEnabled,
}: {
  availableSlots?: QueryAvailabilityResponse;
  selectedDate?: string;
  triggeredBy: TriggeredByOptions;
  biLogger: CalendarBiLogger;
  calendarLayout: LayoutOptions;
  isReportSlotsPerDayEnabled: boolean;
}) => {
  if (isReportSlotsPerDayEnabled) {
    const isTimeSlotsLayout = timeSlotLayouts.includes(calendarLayout);
    const timeSlotsAvailability: Partial<
      Record<DayName, TimeSlotsAvailability>
    > = {};
    const days = getDays({
      calendarLayout,
      selectedDate,
    });
    const slotsPerDay = buildSlotsPerDay(availableSlots);

    days.forEach((day) => {
      const slotsForDay = slotsPerDay[day] || [];
      const timeSlotsAvailabilityStatuses = isTimeSlotsLayout
        ? Array.from(getTimeSlotsAvailabilityStatuses(slotsForDay).values())
        : getSlotAvailabilityStatuses(slotsForDay);

      timeSlotsAvailability[day] =
        mapTimeSlotsByAvailabilityStatusesToTimeSlotsAvailability(
          timeSlotsAvailabilityStatuses,
        );
    });

    void biLogger.report(
      bookingsCalendarTimePickerLoad({
        triggeredBy,
        selectedDate,
        timeSlotsAvailability: JSON.stringify(timeSlotsAvailability),
      }),
    );
  } else {
    const timeSlotsAvailabilityStatuses: Map<
      string,
      TimeSlotAvailabilityStatus
    > = getTimeSlotsAvailabilityStatuses(availableSlots?.availabilityEntries);

    const timeSlotsAvailability =
      mapTimeSlotsByAvailabilityStatusesToTimeSlotsAvailability(
        Array.from(timeSlotsAvailabilityStatuses.values()),
      );

    void biLogger.report(
      bookingsCalendarTimePickerLoad({
        triggeredBy,
        selectedDate,
        timeSlotsAvailability: JSON.stringify(timeSlotsAvailability),
      }),
    );
  }
};

const buildSlotsPerDay = (
  availableSlots: QueryAvailabilityResponse | undefined,
) =>
  availableSlots?.availabilityEntries?.reduce<
    Partial<Record<DayName, SlotAvailability[]>>
  >((slotsPerDay, slot) => {
    const slotDay = getDayName(slot.slot?.startDate!);

    if (!slotsPerDay[slotDay]) {
      slotsPerDay[slotDay] = [];
    }

    slotsPerDay[slotDay]!.push(slot);

    return slotsPerDay;
  }, {}) || {};

const getDays = ({
  calendarLayout,
  selectedDate,
}: {
  calendarLayout: LayoutOptions;
  selectedDate?: string;
}): DayName[] => {
  const isWeeklyLayout = [
    LayoutOptions.WEEKLY_TIMETABLE,
    LayoutOptions.WEEKLY_TIME_SLOTS,
  ].includes(calendarLayout);

  if (isWeeklyLayout) {
    return [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
  } else {
    const selectedDayName = getDayName(selectedDate!);

    return [selectedDayName];
  }
};

export const getSlotAvailabilityStatuses = (
  slotAvailabilities: SlotAvailability[] = [],
): TimeSlotAvailabilityStatus[] =>
  slotAvailabilities.map((slot) => getTimeSlotAvailabilityStatus([slot]));
