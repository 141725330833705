import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  LocalDateTimeRange,
  SlotsStatus,
  TriggeredByOptions,
} from '../../../../types/types';
import { AddError } from '../addError/addError';
import { SetSelectedTime } from '../setSelectedTime/setSelectedTime';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import {
  sendDatePickerLoadedBiEvent,
  sendTimePickerLoadedBiEvent,
} from '../../../../utils/bi/events/events';
import {
  isDailyAgendaWeeklyPickerLayout,
  isDailyTimeSlotsWeeklyPickerLayout,
} from '../../../../utils/layouts';

export type SetSelectedRange = (
  range: LocalDateTimeRange,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedRangeAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  setSelectedTime: SetSelectedTime,
): SetSelectedRange {
  return async (range: LocalDateTimeRange, triggeredBy: TriggeredByOptions) => {
    const [state, setState] = getControllerState();
    const { calendarApi, settings, settingsParams, biLogger, experiments } =
      context;
    const calendarLayout = settings.get(settingsParams.calendarLayout);
    const { fromAsLocalDateTime, toAsLocalDateTime } = range;
    const selectedRange = {
      from: fromAsLocalDateTime,
      to: toAsLocalDateTime,
    };

    let availableSlotsPerDay;
    if (
      isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams) ||
      isDailyAgendaWeeklyPickerLayout(settings, settingsParams)
    ) {
      setState({
        selectedRange,
      });

      availableSlotsPerDay = await calendarApi.getDateAvailability(range, {
        state,
        context,
      });

      setState({
        availableSlotsPerDay,
        selectedDateTrigger: triggeredBy,
      });
    } else {
      setState({
        selectedRange,
        selectedDate: selectedRange.from,
        calendarStatus: CalendarStatus.IDLE,
        slotsStatus: SlotsStatus.LOADING,
        selectedDateTrigger: triggeredBy,
      });
      setSelectedTime(undefined, TriggeredByOptions.WEEK_SELECTED);

      const availableSlots = await calendarApi.getSlotsInRange(range, {
        state,
        context,
        onError: addError,
      });

      if (fromAsLocalDateTime === getControllerState()[0].selectedRange?.from) {
        setState({ availableSlots });

        if (availableSlots?.availabilityEntries?.length) {
          setState({ slotsStatus: SlotsStatus.AVAILABLE_SLOTS });
        } else {
          setState({
            slotsStatus: SlotsStatus.NO_AVAILABLE_SLOTS,
          });
        }

        sendTimePickerLoadedBiEvent({
          availableSlots,
          triggeredBy,
          biLogger,
          calendarLayout,
          isReportSlotsPerDayEnabled: experiments.enabled(
            'specs.bookings.calendar.reportSlotsPerDay',
          ),
        });
      }
    }

    sendDatePickerLoadedBiEvent({
      biLogger,
      triggeredBy,
      availableSlotsPerDay,
    });
  };
}
