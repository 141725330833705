import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  getVariantByChoiceIdAndOptionId,
  isServiceVariantWithStaff,
} from '../../../../utils/dynamicPricing/dynamicPricing';

export type OnServiceVariantsOptionSelected = (options: {
  choiceId: string;
  optionId: string;
  numberOfParticipants: number;
  isMultipleChoices: boolean;
}) => void;

export function createOnServiceVariantsOptionSelected({
  getControllerState,
  context,
}: ActionFactoryParams<
  CalendarState,
  CalendarContext
>): OnServiceVariantsOptionSelected {
  return ({
    choiceId,
    optionId,
    numberOfParticipants,
    isMultipleChoices,
  }: {
    choiceId: string;
    optionId: string;
    numberOfParticipants: number;
    isMultipleChoices: boolean;
  }) => {
    const [state, setState] = getControllerState();
    const { servicesInView, serviceVariantsMap, selectedVariantsOptions } =
      state;

    const service = servicesInView[0];
    const payment = service.payment!;
    const serviceVariants = serviceVariantsMap[service.id];
    const variant = getVariantByChoiceIdAndOptionId(
      serviceVariants,
      choiceId,
      optionId,
    );
    if (isServiceVariantWithStaff(serviceVariants)) {
      setState({
        selectedVariantsOptions: [
          {
            price: variant?.price || {
              currency: payment.paymentDetails.currency,
              value: payment.paymentDetails.price.toString(),
            },
            numberOfParticipants,
            choices: variant?.choices || [
              { optionId, staffMemberId: choiceId },
            ],
          },
        ],
      });
    } else {
      let selectedVariantsOption = selectedVariantsOptions.find(({ choices }) =>
        choices.some(
          ({ optionId: choiceOptionId, custom }) =>
            choiceOptionId === optionId && custom === choiceId,
        ),
      );
      const otherSelectedVariantsOption = isMultipleChoices
        ? selectedVariantsOptions.filter(
            ({ choices }) =>
              !choices.some(
                ({ optionId: choiceOptionId, custom }) =>
                  choiceOptionId === optionId && custom === choiceId,
              ),
          )
        : [];
      if (numberOfParticipants === 0) {
        setState({
          selectedVariantsOptions: [...otherSelectedVariantsOption],
        });
        return;
      }
      if (selectedVariantsOption) {
        selectedVariantsOption = {
          ...selectedVariantsOption,
          numberOfParticipants,
        };
      } else {
        selectedVariantsOption = {
          price: variant?.price || {
            currency: payment.paymentDetails.currency,
            value: payment.paymentDetails.price.toString(),
          },
          numberOfParticipants,
          choices: variant?.choices || [{ optionId, custom: choiceId }],
        };
      }
      setState({
        selectedVariantsOptions: [
          ...otherSelectedVariantsOption,
          selectedVariantsOption,
        ],
      });
    }
  };
}
