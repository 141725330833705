import { Service } from '@wix/bookings-uou-types';
import { Preset } from '../../types/types';
import { isCalendarPage, isCalendarWidget } from '../presets';

export const getAvailableServicesByPreset = ({
  servicesInView,
  preset,
}: {
  servicesInView: Service[];
  preset: Preset;
}) => {
  return servicesInView.length &&
    (isCalendarPage(preset) || isCalendarWidget(preset))
    ? [servicesInView[0]]
    : servicesInView;
};
