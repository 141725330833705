import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';

export type OnServiceChange = (serviceId: string, serviceSlug: string) => void;

export const createOnServiceChange =
  ({
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>): OnServiceChange =>
  (serviceId, serviceSlug) => {
    const [state] = getControllerState();
    const { selectedTimezone } = state;
    const { wixSdkAdapter, biLogger, experiments } = context;

    const referral = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    );

    void biLogger.report(
      bookingsCalendarClick({
        component: WidgetComponents.CHANGE_SERVICE,
        element: WidgetElements.MENU_OPTION,
        serviceId,
      }),
    );

    wixSdkAdapter.navigateToBookingsCalendarPage(
      serviceSlug,
      {
        timezone: selectedTimezone,
        referral,
      },
      experiments.enabled('specs.bookings.RouterPrefixNavigation'),
    );
  };
