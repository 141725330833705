import { Basket } from '@wix/bookings-services-preferences-modal';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { CloseDialogAction } from '../closeDialog/closeDialog';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';

export type OnServicesPreferencesConfirm = (basket: Basket) => void;

export const createOnServicesPreferencesConfirm =
  (
    {
      context,
      getControllerState,
    }: ActionFactoryParams<CalendarState, CalendarContext>,
    closeDialog: CloseDialogAction,
  ): OnServicesPreferencesConfirm =>
  (basket) => {
    const [state] = getControllerState();

    const { selectedTimezone } = state;
    const { wixSdkAdapter, experiments } = context;

    const referral = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    );

    closeDialog();

    wixSdkAdapter.navigateToBookingsCalendarPage(
      basket,
      { referral, timezone: selectedTimezone },
      experiments.enabled('specs.bookings.RouterPrefixNavigation'),
    );
  };
