import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import type Experiments from '@wix/wix-experiments';

export function createWixSdkAdapter(
  controllerConfig: IWidgetControllerConfig,
  experiments: Experiments,
) {
  return new WixOOISDKAdapter(
    controllerConfig.wixCodeApi,
    controllerConfig.platformAPIs,
    controllerConfig.appParams,
    controllerConfig.compId,
    experiments,
  );
}
