import type { PartialServiceV2 } from '@wix/bookings-services-preferences-modal/types';
import {
  OfferedAsType,
  Service,
  ServiceLocation,
  ServiceLocationType,
  ServiceType,
} from '@wix/bookings-uou-types';
import {
  Location as LocationV2,
  LocationType as LocationV2Type,
  Payment as PaymentV2,
  RateType as RateTypeV2,
  ServiceType as ServiceTypeV2,
} from '@wix/ambassador-bookings-services-v2-service/types';

export const mapServiceToPartialServiceV2 = (
  service: Service,
): PartialServiceV2 => {
  return {
    id: service.id,
    category: {
      id: service.info.categoryId,
      name: service.info.categoryName,
    },
    name: service.info.name,
    mainSlug: {
      name: service.info.slugs[0]!,
    },
    staffMemberIds: service.staffMembers.map((staffMember) => staffMember.id),
    staffMembers: service.staffMembers.map(({ id: staffMemberId, name }) => ({
      name,
      staffMemberId,
    })),
    schedule: {
      availabilityConstraints: {
        sessionDurations: [service.info.duration!],
      },
    },
    type: mapServiceTypeToV2(service.info.type),
    locations: service.locations.map(mapServiceLocationToV2),
    payment: mapServicePaymentToV2(service.payment),
  };
};

const mapServicePaymentToV2 = (payment: Service['payment']): PaymentV2 => {
  const { paymentDetails, offeredAs, pricingPlanInfo } = payment;
  const pricingPlanIds = pricingPlanInfo?.pricingPlans.map(
    (pricingPlan) => pricingPlan.id,
  );
  const {
    isVariedPricing,
    minPrice,
    maxPrice,
    isFree,
    currency,
    price,
    priceText,
  } = paymentDetails;

  if (isVariedPricing) {
    return {
      pricingPlanIds,
      rateType: RateTypeV2.VARIED,
      varied: {
        minPrice: minPrice!,
        maxPrice: maxPrice!,
      },
    };
  }

  if (price === 0 || isFree || !offeredAs.includes(OfferedAsType.ONE_TIME)) {
    return {
      pricingPlanIds,
      rateType: RateTypeV2.NO_FEE,
    };
  }

  if (priceText) {
    return {
      pricingPlanIds,
      rateType: RateTypeV2.CUSTOM,
      custom: {
        description: priceText,
      },
    };
  }

  return {
    pricingPlanIds,
    rateType: RateTypeV2.FIXED,
    fixed: {
      price: {
        currency,
        value: price.toString(),
      },
    },
  };
};

const mapServiceLocationToV2 = (location: ServiceLocation): LocationV2 => {
  const { type, businessLocation, locationText } = location;

  if (type === ServiceLocationType.CUSTOM) {
    return {
      type: LocationV2Type.CUSTOMER,
    };
  }

  if (type === ServiceLocationType.OWNER_CUSTOM) {
    return {
      type: LocationV2Type.CUSTOM,
      calculatedAddress: {
        formattedAddress: locationText,
      },
      custom: {
        address: {
          formattedAddress: locationText,
        },
      },
    };
  }

  return {
    type: LocationV2Type.BUSINESS,
    business: {
      id: businessLocation?.id,
      name: businessLocation?.name,
    },
  };
};

const mapServiceTypeToV2 = (
  serviceType: ServiceType,
): PartialServiceV2['type'] => {
  if (serviceType === ServiceType.COURSE) {
    return ServiceTypeV2.COURSE;
  }

  if (serviceType === ServiceType.GROUP) {
    return ServiceTypeV2.CLASS;
  }

  return ServiceTypeV2.APPOINTMENT;
};
