import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getVariantByChoiceIdAndOptionId } from '../../../../utils/dynamicPricing/dynamicPricing';
import { mapServiceToPartialServiceV2 } from '../../../../utils/service/mapServiceToV2';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { CalendarState } from '../../controller';
import {
  Basket,
  PartialServiceV2,
} from '@wix/bookings-services-preferences-modal/types';

export type OpenServicesPreferencesModal = () => Promise<void>;

export const createOpenServicesPreferencesModalAction =
  ({
    getControllerState,
    context,
  }: ActionFactoryParams<
    CalendarState,
    CalendarContext
  >): OpenServicesPreferencesModal =>
  async () => {
    const { createModalData } = await import(
      '@wix/bookings-services-preferences-modal/utils'
    );
    const { enrichModalData } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );
    const [state, setState] = getControllerState();
    const { servicesInView, serviceVariantsMap } = state;
    const { businessInfo, calendarSelections } = context;

    if (!calendarSelections) {
      return;
    }

    const servicesV2 = servicesInView.map(mapServiceToPartialServiceV2);
    const serviceIdServiceMap = servicesV2.reduce<
      Record<string, PartialServiceV2>
    >((acc, service) => ({ ...acc, [service.id!]: service }), {});
    const firstLocation = servicesV2?.[0].locations?.[0];
    const locationType = firstLocation?.type!;
    const basket: Basket = {
      location: {
        type: locationType,
        value:
          calendarSelections.location ||
          firstLocation?.custom?.address?.formattedAddress ||
          '',
      },
      selectedServicesPreferences: calendarSelections.services.map(
        ({ id, resources, variants }) => {
          const service = serviceIdServiceMap[id];
          let variant = variants?.[0];

          if (variant) {
            const serviceVariants = serviceVariantsMap[service.id!];
            const choiceId =
              variant.choices?.[0].custom ||
              variant.choices?.[0].staffMemberId!;
            const optionId = variant.choices?.[0].optionId!;
            const selectedVariant = getVariantByChoiceIdAndOptionId(
              serviceVariants,
              choiceId,
              optionId,
            );

            variant = selectedVariant;
          }

          return {
            service,
            resources: {
              staff: resources,
            },
            variant,
          };
        },
      ),
    };

    const servicesPreferencesModalData = createModalData({
      regionalSettingsLocale: businessInfo?.regionalSettingsLocale!,
      service: servicesV2[0],
      basket,
    });

    setState({
      servicesPreferencesModalData,
      dialog: { state: DialogState.IDLE, type: DialogType.ServicesPreferences },
    });

    enrichModalData({
      httpClient: context.flowAPI.httpClient,
      service: servicesV2[0],
      basket,
      getServicesPreferencesModalData: () =>
        getControllerState()[0].servicesPreferencesModalData!,
      setServicesPreferencesModalData: (data) =>
        setState({ servicesPreferencesModalData: data }),
      prefetchedData: {
        getServiceOptionsAndVariants: state.serviceVariantsMap,
      },
    });
  };
